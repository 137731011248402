import Extractor from "@jordibosch20/software_tools_package/dist/pages/Extractor.component.js"
import styles from "./App.module.css";
import { getURLs } from "@jordibosch20/software_tools_package/dist/utils/url-extractor.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
    <div className={styles.App}>
      <Extractor title={"URL extractor"} f={getURLs}></Extractor>
    </div>
    <div className={styles.text}>
      <p><strong>Introduction</strong>: In the digital age, where data is king, having the right tools to manage and extract valuable information is crucial. Our Bulk URL Extractor stands out as a game-changer for professionals across various industries. Whether you're diving into competitive analysis, conducting comprehensive research, or managing web content, extracting URLs from extensive text can be a daunting task. Our tool simplifies this process, offering a seamless solution to identify and extract every URL, saving you time and enhancing your productivity.</p>
      <p><strong>Why Choose Our Bulk URL Extractor?</strong></p>
      <ul>
      <li><strong>Efficiency at Scale</strong>: Handle large datasets with ease, ensuring no URL is missed, regardless of the text volume.</li>
      <li><strong>Precision and Accuracy</strong>: Our advanced algorithms ensure that every extracted URL is accurate, providing you with reliable data for your projects.</li>
      <li><strong>User-Friendly Interface</strong>: Designed with simplicity in mind, our tool allows users of all technical abilities to extract URLs effortlessly.</li>
      <li><strong>Time-Saving Solution</strong>: Automate the tedious task of manually searching for and copying URLs, freeing up your time for more strategic activities.</li>
      <li><strong>Versatile Application</strong>: Ideal for digital marketers, SEO specialists, researchers, and web developers, our tool supports a wide range of projects and tasks.</li>
      </ul>
      <p><strong>How It Works</strong>:</p>
      <ol>
      <li><strong>Input Your Text</strong>: Paste the text containing the URLs into our extractor tool.</li>
      <li><strong>Extract with a Click</strong>: Initiate the extraction process and let our tool work its magic, identifying and compiling all URLs.</li>
      <li><strong>Collect and Analyze</strong>: Access a cleanly extracted list of URLs, ready for review, analysis, or further processing.</li>
      </ol>
      <p><strong>Applications Across Various Fields</strong>:</p>
      <ul>
      <li><strong>Digital Marketing and SEO</strong>: Gather URLs for backlink analysis, competitor website studies, or content strategy development.</li>
      <li><strong>Academic and Market Research</strong>: Compile sources and references from extensive documents or online content.</li>
      <li><strong>Web Development and Content Management</strong>: Identify and manage external links within website content or databases.</li>
      </ul>
      <p></p>
      <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;